@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Afacad:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=ABeeZee:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Antonio:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap');


html {
    scroll-behavior: smooth;
}

/* Masquer la barre de défilement pour les navigateurs modernes */
.no-scrollbar::-webkit-scrollbar {
    display: none; /* pour Chrome, Safari et Opera */
  }
  .no-scrollbar {
    -ms-overflow-style: none;  /* pour Internet Explorer et Edge */
    scrollbar-width: none;  /* pour Firefox */
  }
  

